import { CSVColumn, CSVFormat, CSV_CLIENT_COLUMN_TYPES, CSV_FORMAT } from "@sasagase/types";
import { useMemo } from "react";
import { useAppState } from "../context";
import { isProduction } from "./isProduction";

const KOMARI_SHOP_ID = '4918bfcd-2c22-4a1f-a49e-06260e8268ab';

export function isKomari(shopId: string): boolean {
	return shopId === KOMARI_SHOP_ID || !isProduction;
}

export function isAvailableCSVType(shopId: string, type: string): boolean {
	if (type === 'sasagase') {
		return isKomari(shopId);
	}
	return type === 'csv';
}

export function isRetransactableCSVFormat(formatId: string): boolean {
	const format = CSV_FORMAT.find((format) => format.id === formatId);
	return format != null && format.type === 'csv';
}

export function useAvailableCSVFormats(): CSVFormat[] {
	const [state] = useAppState();
	const availableCSVFormats = useMemo(() => {
		return CSV_FORMAT.filter((csv) => isAvailableCSVType(state.shop?.id ?? '', csv.type));
	}, [state.shop?.id]);
	return availableCSVFormats;
}

export function isUserCSVField(col: CSVColumn) {
	return col.type === 'user' || (CSV_CLIENT_COLUMN_TYPES as ReadonlyArray<string>).includes(col.type);
}

export function useAvailableOptions(): Record<string, boolean> {
	const [state] = useAppState();
	const availableOptions = useMemo(() => {
		if (isKomari(state.shop?.id ?? '')) {
			return {
				'link': true,
				'kifuda': true,
				'maruKifuda': true,
				'maruKifudaM': true,
				'namaebata': true,
				'namaekonori': true,
				'namaemagnet': true,
			};
		}
		return {} as Record<string, boolean>;
	}, [state.shop?.id]);
	return availableOptions;
}
