import { parseJapaneseDate } from "../parseJapaneseDate";
import { KAMON_INFO } from "./kamon";

/** 発注書の形式 */
export interface PurchaseOrderMethod {
	/** 発注書種類名 */
	name: string;

	/** メーカー */
	supplier: string;

	/** 発注方法 */
	type: 'fax' | 'spreadsheet' | 'excel';

	/** 発注書1枚あたりに記載できる商品数 */
	perItems: number;

	/** 直送指定可能かどうか */
	canDirect?: boolean;

	/** 発注書ごとの記載事項 */
	values: PurchaseOrderValue[];

	/** 商品ごとの記載事項 */
	itemValues: PurchaseOrderValue[];
}

/** 発注書の記載事項の形式 */
export interface PurchaseOrderValue {
	/** キー名 */
	key: string;

	/** 項目名 */
	name: string;

	/** 値の種別 */
	type: 'list' | 'string' | 'text' | 'date' | 'boolean';

	/** list時に指定可能な値のリスト */
	list?: string[];

	/** listの値に表示名がある場合の対応表 */
	map?: Record<string, string>;

	/** 最小文字数 */
	min?: number;

	/** 最大文字数 */
	max?: number;

	/** 省略可能 */
	isOmittable?: boolean;
}

export function isValidPurchaseOrderValue(value: unknown, format: PurchaseOrderValue): boolean {
	const typeValidator: Record<string, (val: unknown) => boolean> = {
		'list': (val: unknown) => typeof val == 'string' && (format.list?.includes(val) ?? false),
		'string': (val: unknown) => typeof val == 'string' && (format.min ?? 0) <= val.length && val.length <= (format.max ?? Infinity),
		'text': (val: unknown) => typeof val == 'string' && (format.min ?? 0) <= val.length && val.length <= (format.max ?? Infinity),
		'date': (val: unknown) => typeof val == 'string' && !Number.isNaN(toDate(val).getTime()),
		'boolean': (val: unknown) => typeof val == 'boolean',
	};
	if (value == null) {
		return Boolean(format.isOmittable);
	}
	return typeValidator[format.type]?.(value) ?? false;
}

export function toDate(val: string): Date {
	const date = new Date(val);
	if (!Number.isNaN(date.getTime())) {
		return date;
	}
	return parseJapaneseDate(val) ?? date;
}

export const PURCHASE_ORDER_METHODS: Record<string, PurchaseOrderMethod> = {
	'woody-kifuda': {
		name: 'WOODY 木札',
		supplier: 'woody',
		type: 'fax',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'product', name: '商品名', type: 'list', list: ['木札大(サイズ3)', '木札小(サイズ2)', '名前旗S チェリー', '名前旗S ウォールナット', '名前旗SS チェリー', '名前旗SS ウォールナット', '名前旗XS チェリー', '名前旗XS ウォールナット'] },
			{ key: 'graphType', name: '絵柄・家紋種別', type: 'list', list: ['絵柄', '家紋'] },
			{ key: 'graphNumber', name: '絵柄・家紋No', type: 'list', list: KAMON_INFO.map((kamon) => kamon.id), map: Object.fromEntries(KAMON_INFO.map((kamon) => [kamon.id, kamon.name])) },
			{ key: 'birthday', name: '生年月日', type: 'date' },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 3 },
			{ key: 'furigana', name: 'ふりがな', type: 'string', isOmittable: true },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
			{ key: 'isReviewReward', name: 'レビュー特典', type: 'boolean', isOmittable: true },
			{ key: 'isDirect', name: '直送', type: 'boolean', isOmittable: true },
		],
	},
	'woody-kifuda-maru': {
		name: 'WOODY 木札 丸型',
		supplier: 'woody',
		type: 'fax',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'product', name: '商品名', type: 'list', list: ['丸型(8cm)', '丸型(6cm)'] },
			{ key: 'pattern', name: '文字・向き', type: 'list', list: ['名前日本語×和暦-縦書き', '名前日本語×和暦-横書き', '名前日本語×西暦-横書き'] },
			{ key: 'graphType', name: '絵柄・家紋種別', type: 'list', list: ['絵柄', '家紋'] },
			{ key: 'graphNumber', name: '絵柄・家紋No', type: 'list', list: KAMON_INFO.map((kamon) => kamon.id), map: Object.fromEntries(KAMON_INFO.map((kamon) => [kamon.id, kamon.name])) },
			{ key: 'birthday', name: '生年月日', type: 'date' },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 3 },
			{ key: 'furigana', name: 'ふりがな', type: 'string', isOmittable: true },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
			{ key: 'isReviewReward', name: 'レビュー特典', type: 'boolean', isOmittable: true },
			{ key: 'isDirect', name: '直送', type: 'boolean', isOmittable: true },
		],
	},
	'woody-kifuda-mini': {
		name: 'WOODY 木札 ミニサイズ',
		supplier: 'woody',
		type: 'fax',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'product', name: '商品名', type: 'list', list: ['お名前木札 ミニサイズ', 'お名前木札 半円ミニサイズ'] },
			{ key: 'pattern', name: '文字・向き', type: 'list', list: ['名前ローマ字×西暦-横書き', '名前日本語×和暦-横書き', '名前日本語×西暦-横書き', '名前日本語×和暦-縦書き'] },
			{ key: 'birthday', name: '生年月日', type: 'date' },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 3, isOmittable: true },
			{ key: 'romaji', name: 'ローマ字', type: 'string', min: 1, max: 10, isOmittable: true },
			{ key: 'furigana', name: 'ふりがな', type: 'string', isOmittable: true },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
			{ key: 'isReviewReward', name: 'レビュー特典', type: 'boolean', isOmittable: true },
		],
	},
	'woody-koi': {
		name: 'WOODY 鯉のぼり',
		supplier: 'woody',
		type: 'fax',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'product', name: '商品名', type: 'list', list: ['名入れ鯉のぼり 1匹', '名入れ鯉のぼり 2匹(名入れ・うろこ絵柄)', '名入れ鯉のぼり 2匹(名入れ・名入れ)', 'オプション追加鯉名入れ 単品', 'オプション追加鯉名入れ＆支柱'] },
			{ key: 'graphType', name: '絵柄・家紋種別', type: 'list', list: ['絵柄', '家紋'], isOmittable: true },
			{ key: 'graphNumber', name: '絵柄・家紋No', type: 'list', list: KAMON_INFO.map((kamon) => kamon.id), map: Object.fromEntries(KAMON_INFO.map((kamon) => [kamon.id, kamon.name])), isOmittable: true },
			{ key: 'pattern', name: '鯉二匹目', type: 'list', list: ['なし', 'うろこ絵柄', '名入れ'] },
			{ key: 'name1', name: '名前（一人目）', type: 'string', min: 1, max: 3 },
			{ key: 'birthday1', name: '生年月日（一人目）', type: 'date' },
			{ key: 'name2', name: '名前（二人目）', type: 'string', min: 1, max: 3, isOmittable: true },
			{ key: 'birthday2', name: '生年月日（二人目）', type: 'date', isOmittable: true },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
			{ key: 'isReviewReward', name: 'レビュー特典', type: 'boolean', isOmittable: true },
		],
	},
	'bizan-namaebata': {
		name: '美山 名前旗',
		supplier: 'bizan',
		type: 'fax',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'itemId', name: '商品番号', type: 'string' },
			{ key: 'itemName', name: '商品名', type: 'string' },
			{ key: 'fittingId', name: '付属品：商品番号', type: 'string', isOmittable: true },
			{ key: 'fittingName', name: '付属品：商品名', type: 'string', isOmittable: true },
			{ key: 'graphType', name: '家紋名', type: 'string', isOmittable: true },
			{ key: 'birthday', name: '生年月日', type: 'date', isOmittable: true },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 3 },
			{ key: 'furigana', name: 'ふりがな', type: 'string', isOmittable: true },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
		],
	},
	'takahashi-namaebata': {
		name: '高橋工芸 名前旗',
		supplier: 'takahashi',
		type: 'spreadsheet',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'type', name: '種類', type: 'list', list: ["こまり月虎 黒", "こまり月虎 麻葉黒", "こまり月虎 白", "こまり松鷹 黒(茶)", "こまり松鷹 白(茶)", "こまり松鷹 麻葉黒(グレー)", "こまり松鷹 白(グレー)", "こまり真龍 黒", "こまり真龍 白", "こまり新真龍 麻葉黒", "こまり新真龍 白地", "無地紋様 紺", "無地紋様 赤", "無地紋様 白", "無地紋様 ベージュ", "麻の葉 緑", "麻の葉 紫", "和モダン白", "こまり和モダン同色桜くすみ配", "室町 赤", "室町 白", "室町くすみ配", "千鳥 白", "荒巻龍 白", "荒巻龍 黒", "切嵌めこまり月トラ", "切嵌めこまり松タカ", "切嵌めこまり新真龍", "切嵌め鱗 白", "切嵌め鱗 黒"] },
			{ key: 'size', name: 'サイズ', type: 'list', list: ["ミニ14", "中30", "小20", "大40", "特注(22)"] },
			{ key: 'thread', name: '刺繍糸', type: 'list', list: ["金", "ベージュ A3360", "緑 A441", "白", "ワインN６", "ブラウン132", "濃桃色P1326", "黄色A333", "黄緑色P1071", "緑色P2920", "薄桃色A3", "紫色P1124", "水色P1229", "紺色P2805", "紫A100", "黄色P1098", "ピンクP1012", "ブラウンP1165", "薄ワインA3408", "白ラメ", "濃ピンクP1358", "薄ピンクA3", "肌ピンクP1177", "フジA100", "キイロP1098", "青P1144", "グレー"], isOmittable: true },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 3 },
			{ key: 'birthday', name: '生年月日', type: 'date', isOmittable: true },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
			{ key: 'isReviewReward', name: 'レビュー特典', type: 'boolean', isOmittable: true },
			{ key: 'isDirect', name: '直送', type: 'boolean', isOmittable: true },
		],
	},
	'hinatsune-namaebata': {
		name: '雛常 名前旗',
		supplier: 'hinatsune',
		type: 'spreadsheet',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'color', name: '色', type: 'list', list: ["赤", "サーモンピンク", "ピンク", "ベージュ", "マリ(サーモンピンク)", "ピンクマリ", "ホワイトマリ", "生成マリ", "黒", "グリーン(もえぎ)", "紺(藍)", "とんぼ", "鯉(藍)", "タカ", "虎", "ホワイト鯉", "生成鯉", "ナチュラル", "ベージュナチュラル", "ダークブルー"] },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 5 },
			{ key: 'birthday', name: '生年月日', type: 'date', isOmittable: true },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
		]
	},
	'miyahide-namaebata': {
		name: '宮秀 名前旗',
		supplier: 'miyahide',
		type: 'fax',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'number', name: '品番', type: 'list', list: ["三-1", "三-4", "三-8", "三-9", "五-1", "五-2", "五-5", "五-6", "五-7"] },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 3 },
			{ key: 'furigana', name: 'ふりがな', type: 'string', isOmittable: true },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
		],
	},
	'miyahide-petdoll': {
		name: '宮秀 ペットお祝い人形',
		supplier: 'miyahide',
		type: 'fax',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'number', name: '品番', type: 'list', list: ["ひなまる", "たんごまる"] },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 9 },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
		],
	},
	'inoue-naire': {
		name: '井上製鯉 名入れ',
		supplier: 'inoue',
		type: 'excel',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'itemName', name: '商品名', type: 'string' },
			{ key: 'purchaser', name: '購入者', type: 'string' },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 3 },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
		],
	},
	'inoue-hagoita': {
		name: '井上製鯉 羽子板',
		supplier: 'inoue',
		type: 'excel',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'purchaser', name: '購入者', type: 'string' },
			{ key: 'itemNumber', name: '商品番号', type: 'string' },
			{ key: 'itemName', name: '商品名', type: 'string' },
			{ key: 'name', name: '名入れ', type: 'string', min: 1, max: 3 },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
		],
	},
	'inoue-kareflower': {
		name: '井上製鯉 カレフラワー',
		supplier: 'inoue',
		type: 'excel',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'purchaser', name: '購入者', type: 'string' },
			{ key: 'itemNumber', name: '商品番号', type: 'string' },
			{ key: 'itemName', name: '商品名', type: 'string' },
			{ key: 'name', name: '名入れ', type: 'string', min: 1, max: 10 },
			{ key: 'birthday', name: '生年月日', type: 'date', isOmittable: true },
			{ key: 'message', name: 'メッセージ', type: 'string' },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
		],
	},
	'inoue-namaebata': {
		name: '井上製鯉 名前旗',
		supplier: 'inoue',
		type: 'fax',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'itemCode', name: '商品コード', type: 'string' },
			{ key: 'itemName', name: '商品名', type: 'string' },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 3 },
			{ key: 'birthday', name: '生年月日', type: 'date' },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
		],
	},
	'interlab-kifuda': {
		name: 'インターラボ 名前木札',
		supplier: 'interlab',
		type: 'spreadsheet',
		perItems: 1,
		canDirect: true,
		values: [],
		itemValues: [
			{ key: 'itemName', name: '品名', type: 'list', list: ["[デザイン:桜]", "[デザイン:梅]", "[デザイン:まる-雛-]", "[デザイン:まる-端午-]", "[デザイン:まる-無地-]", "[デザイン:しかく-雛-]", "[デザイン:しかく-端午-]", "[デザイン:しかく-無地-]"] },
			{ key: 'name', name: '名前', type: 'string', min: 1, max: 3 },
			{ key: 'remarks', name: '備考', type: 'text', isOmittable: true },
			{ key: 'isReviewReward', name: 'レビュー特典', type: 'boolean', isOmittable: true },
		],
	},
};
