import { isProduction } from "./isProduction";
import { isKomari } from "./shopCustom";

// Prod: https://link.rakuten.co.jp/1/113/432/?url=XXXXXXXX → https://present.r-review.sasagase.jp/XXXXXXXX
const REVIEW_FORM_URI = process.env.REVIEW_FORM_URI ?? (isProduction ? 'https://link.rakuten.co.jp/1/113/432/' : 'http://localhost:3003/');
const PREFER_FORM_URL = 'https://link.rakuten.co.jp/0/068/412/';

export function toReviewFormUrl(token: string, shopId: string): string {
	let url = new URL(REVIEW_FORM_URI);
	if (isProduction && url.host.match(/link.rakuten/)) {
		// 楽天版ぷりふあ専用の申込みフォームURL
		if (isKomari(shopId)) {
			url = new URL(PREFER_FORM_URL);
		} else {
			url.searchParams.set('url', token);
		}
	} else {
		url = new URL(token, url);
	}
	return url.toString();
}
export default toReviewFormUrl;
